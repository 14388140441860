import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import logoWhite from 'assets/super-saubi-logo.png';
import dermatest from 'assets/icons/dermatest.svg';

export const Nav = () => {
  const [open, setOpen] = useState(false);
  const [lastLocation, setLastLocation] = useState('');
  const { pathname } = useLocation();

  useEffect(() => {
    if (lastLocation !== pathname) {
      setOpen(false);
    }
    setLastLocation(pathname);
  }, [pathname]);

  return (
    <nav className="row flex-align-center">
      <div className="container-big row justify-end flex-align-center">
        <Link className="logo-container" to="/">
          <img className="logo" src={logoWhite} alt="" />
        </Link>

        <div
          className="nav-button row flex-align-center justify-start pointer"
          onClick={() => setOpen(true)}
        >
          <div className="nav-button__line"></div>
          <div className="nav-button__line"></div>
          <div className="nav-button__line"></div>
        </div>
        <div
          className={`actual-nav column flex-align-center ${open ? 'open' : ''}`}
        >
          <div
            className="row fw flex-align-center justify-end"
            style={{ padding: '4px 0 80px' }}
          >
            <div
              className="nav-close btn-close justify-self-end"
              onClick={() => setOpen(false)}
            >
              <div className="btn-close__line"></div>
              <div className="btn-close__line"></div>
            </div>
          </div>
          <img
            className="logo"
            src={logoWhite}
            alt=""
            style={{ marginBottom: '20px' }}
          />
          <Link to="/legal/impressum">Impressum</Link>
          <Link to="/legal/datenschutz">Datenschutz</Link>
          <div className="column" style={{ marginTop: '24px' }}>
            <a className="contact" href="mailto:hallo@certainly.at">
              hallo@certainly.at
            </a>
          </div>
          <a
            href="/pdf/DermatestSuperSaubi_TYP-GMBH.pdf"
            target="_blank"
            style={{ maxWidth: '200px', marginTop: '24px', width: '100%' }}
          >
            <img src={dermatest} alt="Dermatest Logo" />
          </a>
        </div>
      </div>
    </nav>
  );
};
