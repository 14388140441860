import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { DropShadow } from '../components/svg-filter';
import shopBtn from 'assets/shop-button.svg';
import { Nav } from './Nav';
import SmoothScroll from '../components/SmoothScroll';

const Home = React.lazy(() => import('../pages/Home'));
const Legal = React.lazy(() => import('../pages/Legal'));

export default class MainRouter extends Component {
  render() {
    return (
      <>
        <DropShadow />
        <Helmet>
          <html lang="de" />
          <title>Super Saubi - Desinfektionsmittel</title>
          <meta charset="utf-8" />
          <meta name="author" content="Synelution GmbH Klagenfurt" />
          <meta
            name="description"
            content="Super Saubi - Das Desinfektionsmittel ohne Alkohol für saubere Kinderhände! jetzt bestellen."
          />
        </Helmet>
        <Nav />
        {/* <a
          className="shop-btn align-center"
          target="_blank"
          rel="noopener noreferrer"
          href="https://certainly-desinfektionsmittel.myshopify.com/collections/super-saubi"
        >
          Zum Shop
        </a> */}
        <Suspense fallback={null}>
          <div className="main-content">
            {/* <SmoothScroll> */}
            <Switch>
              <Route exact path="/legal/:slug" component={Legal} />
              <Route exact path="/" component={Home} />
            </Switch>
            {/* </SmoothScroll> */}
          </div>
        </Suspense>
      </>
    );
  }
}
